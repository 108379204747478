// src/components/Modal.tsx
import React, { useEffect, useState } from 'react';
import ApiService from '../services/ApiService';
import { Person, Video } from 'common-lib/generated-client';
import SocialMediaList from './SocialMediaList';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './PersonDetailsModal.css';
import { CustomLeftArrow, CustomRightArrow } from 'components-lib';
import FollowExplanationModal from './FollowExplanationModal';

interface ModalProps {
  person: Person | null;
  onClose: () => void;
}

const PersonDetailsModal: React.FC<ModalProps> = ({ person, onClose }) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [displayFollowExplanation, setDisplayFollowExplanation] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    if (!person) {
      return;
    }
    const fetchVideos = async (authorCode: string) => {
      try {
        const response = await ApiService.getVideos(authorCode);
        setVideos(response.videos ?? []);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };
    if (person?.authorCode) fetchVideos(person.authorCode);
  }, [person]);
  if (!person) {
    return null;
  }
  const getResponsiveCarouselSetup = (itemCount: number) => {
    return {
      tablet: {
        breakpoint: { max: 3000, min: 600 },
        items: Math.min(2, itemCount ?? 1),
      },
      mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
      },
    };
  };
  const onFollow = async () => {
    try {
      const extensionId = process.env.REACT_APP_SUREMARK_EXTENSION_ID;
      const result = await chrome.runtime.sendMessage(extensionId, {
        type: 'FOLLOW',
        follow: person.authorCode,
      });
      if (!result) {
        console.error('Error following person:', chrome.runtime.lastError);
        setDisplayFollowExplanation(true);
        return;
      }
      if (result.status === 'ok') {
        setIsFollowing(true);
      }
    } catch (error) {
      console.error('Exception following person:', error);
      setDisplayFollowExplanation(true);
    }
  };
  return (
    <div className="top-space">
      <div className="modal-overlay" onClick={onClose}>
        <div className="close-button" onClick={onClose}></div>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="profile-container">
            <img
              src={ApiService.getPersonImageURL(person)}
              alt={`${person.firstName} ${person.lastName}`}
              className="profile-picture"
            />
            <div className="profile-details">
              <h1>
                {person.firstName} {person.lastName}
              </h1>
              <p className="suremark-code">
                {person.vanityTag && person.vanityTag !== person.authorCode ? (
                  <>
                    /S/{person.vanityTag} (/S/{person.authorCode})
                  </>
                ) : (
                  <>/S/{person.authorCode}</>
                )}
              </p>
              <SocialMediaList person={person} />
              <button className="button" disabled={isFollowing} onClick={onFollow}>
                {isFollowing ? 'Following' : 'Follow'}
              </button>
            </div>
          </div>
          {videos.length ? (
            <>
              <div className="carousel-container">
                <Carousel
                  responsive={getResponsiveCarouselSetup(videos.length)}
                  customLeftArrow={<CustomLeftArrow />}
                  customRightArrow={<CustomRightArrow />}
                >
                  {videos.map((video, i) => (
                    <div key={i} className="carousel-item">
                      <p className="video-title">
                        {video.authorCodes?.map((c) => `/S/${c}`).join(', ')}
                      </p>
                      <video controls className="fixed-width-visual" preload="metadata">
                        <source src={video.location} type={video.mediaType} />
                      </video>
                      <p className="video-title">{video.title}</p>
                    </div>
                  ))}
                </Carousel>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {/* Conditionally render FollowExplanationModal */}
      {displayFollowExplanation && (
        <FollowExplanationModal onClose={() => setDisplayFollowExplanation(false)} />
      )}
    </div>
  );
};

export default PersonDetailsModal;
