import React from 'react';
import './modal.css';

interface FollowExplanationModalProps {
  onClose: () => void;
}
const FollowExplanationModal: React.FC<FollowExplanationModalProps> = ({ onClose }) => {
  return (
    <div className="confirmation-modal">
      <div className="sub-modal-content">
        <h3>Cannot Follow This Person Automatically</h3>
        <p>To follow this individual you first need to install the extension</p>
        <div className="button-container">
          <a className="confirm-btn" href={process.env.REACT_APP_SUREMARK_EXTENSION_URL}>
            Download SureMark Id Browser Extension
          </a>
          <button className="cancel-btn" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FollowExplanationModal;
